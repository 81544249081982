import React from 'react';

import Layout from '../../components/MenuLayout';
import { CasesView } from '../../components/Case/CasesView';
import { CasesPatientView } from '../../components/Case/CasesPatientView';
import { getUserInterface } from '../../utils/patientUserHospitalUtils';

const userInterface = getUserInterface();

export default () => (
  <Layout>
    {userInterface === 'patient' ? <CasesPatientView /> : <CasesView />}
  </Layout>
);
