import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';

import { Box, capitalize, Container, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete, Edit, ArrowDropDown, ArrowDropUp, OpenInNew, Sort } from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { SectionBar } from '../../componentsUI/SectionBar';

import { GET_CASES } from '../../graphql/queries';
import TableCollapse from '../../componentsUI/tableCollapse';
import { CaseCollapse } from './CaseCollapse';
import { ACTIVECASE2, GetFormat } from '../../utils/functions';
import { DeleteCaseDialog } from './modal/DeleteCaseDialog';
import { NewCaseDialog } from './modal/NewCaseDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const useStyles = makeStyles(() => ({
  studyInfo: {
    display: 'inline',
  },
  studyDate: {
    display: 'inline',
    fontSize: '.9em',
    fontWeight: 800,
    color: '#888',
    marginLeft: 12,
  },
}));

const fieldNames = [
  { label: 'patient', id: 'node.patient', field: 'PATIENT_LAST_NAME', direction: 'ASC', width: 125, format: 'PATIENTNAME' },
  { label: 'active', id: 'node.active', field: 'ACTIVE', direction: 'DESC', width: 5, format: 'ACTIVECASE2' },
  { label: 'case.created', id: 'node.createdAt', field: 'CREATED_AT', direction: 'DESC', width: 100, format: 'DATEFORMAT', align: 'center' },
  { label: 'description', id: 'node.title', field: 'TITLE', direction: 'ASC', width: 250 },
];

export const CasesView = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const sortData = {
    default: { field: 'CREATED_AT', direction: 'DESC' },
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_CASES, {
      variables: {
        hospitalUuid,
        first: 50,
        orderBy: {
          field: orderByField,
          direction: orderByDirection,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const medicalCases = data && data.medicalCases && data.medicalCases.edges;
  const medicalCase = (medicalCases && selected.length > 0) && medicalCases[selected[0]].node;

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = data.medicalCases.edges[index].node;

    navigate(`/case/${indexCase.uuid}`);
  };

  const fetchMoreCases = (fetchMoreCb) => {
    const { endCursor } = data.medicalCases.pageInfo;
    fetchMore({
      query: GET_CASES,
      variables: {
        hospitalUuid,
        first: 50,
        cursor: endCursor,
        orderBy: {
          field: orderByField,
          direction: orderByDirection,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult && fetchMoreResult.medicalCases.edges;

        return newEdges.length ? {
          medicalCases: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.medicalCases.__typename,
            totalCount: previousResult.medicalCases.totalCount,
            edges: [...previousResult.medicalCases.edges, ...newEdges],
            pageInfo: fetchMoreResult.medicalCases.pageInfo,
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.medicalCases.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const getTitle = (item) => {
    const formattedDate = item.node.createdAt && GetFormat(item.node.createdAt, 'DATEFORMAT');
    return (
      <>
        {GetFormat(item.node.active, ACTIVECASE2)}
        {item.node.title}
        <Box className={classes.studyDate}>
          {` ${formattedDate}`}
        </Box>
      </>
    );
  };

  const getSubTitle = (item) => {
    const getPatientName = (patient) => (`${patient.name} ${patient.surname}`);
    return (
      <Box className={classes.studyInfo}>
        <Box component="span">
          {`${capitalize(t('patient'))}: `}
          <Box component="span" fontWeight={700}>
            <Link to={`/patient/${item.node.patient.uuid}`}>
              {getPatientName(item.node.patient)}
            </Link>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleCloseNew = () => {
    setShowNewModal(false);
    refetch();
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    refetch();
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'new.case', icon: Add, I: 'view', handleClick: () => navigate('/cases/new'), disabled: false },
    { name: 'view.case', icon: OpenInNew, handleClick: () => navigate(`/case/${medicalCase.uuid}`), disabled: selected.length !== 1 },
    { name: 'edit.case', icon: Edit, I: 'edit', data: medicalCase, handleClick: () => navigate(`/case/edit/${medicalCase.uuid}`), disabled: selected.length !== 1 },
    { name: 'delete.case', icon: Delete, I: 'delete', data: medicalCase, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>
        <DeleteCaseDialog open={showDeleteModal} onClose={handleCloseDelete} medicalCase={medicalCase} />
        <NewCaseDialog open={showNewModal} onClose={handleCloseNew} caseId={medicalCase && medicalCase.uuid} refetch={refetch} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="cases" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          { loading && isEmpty(data) && <Loading /> }

          <CardWrapperUI>
            {
              (data && data.medicalCases)
                && (
                  <TableCollapse
                    fieldNames={fieldNames}
                    items={data.medicalCases.edges}
                    GetTitle={getTitle}
                    GetSubtitle={getSubTitle}
                    GetCollapse={CaseCollapse}
                    selected={selected}
                    setSelected={setSelected}
                    handleGoto={handleGoto}
                    isMore={data
                      && data.medicalCases
                      && data.medicalCases.pageInfo
                      && data.medicalCases.pageInfo.hasNextPage}
                    fetchMoreFn={fetchMoreCases}
                    order={{ field: orderByField, direction: orderByDirection }}
                    handleOrder={handleOrderBy}
                    openTooltip="view.case"
                  />
                )
            }
          </CardWrapperUI>
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
